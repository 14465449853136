.form-control {
  width: 90%;
  display: flex;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 10px;
  margin-bottom: 10px;
  &__icons {
    margin-right: 10px;
    margin-top: 10px;
    width: 25px;
    height: 25px;
  }
  &__input {
    width: 100%;
  }
  &__actions {
    min-width: 48px;
  }
}
