.control-instance-timer {
   //TODO: put all icons into container and apply common behaviour
  cursor: default;
  color: #7dcfed;
  font-weight: 700;
  border: 2px solid #7dcfed;
  border-radius: 10px;
  display: flex;
  padding: 5px 10px;
  margin-left: 10px;
  margin-right: 10px;
  &__hours {
    padding-right: 5px;
  }
}