.react-datepicker {
  border: 1px solid #7dcfed;
}

.react-datepicker__header {
  background-color: #7dcfed;
}

.react-datepicker__current-month, .react-datepicker__day-name, .react-datepicker-time__header {
  color: #fff;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range {
  border-radius: 50%;
  background-color: #7dcfed;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover {
  background-color: #7dcfed;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-top: none;
  border-bottom-color: #7dcfed;
  &::before {
    border-top: none;
    border-bottom-color: #7dcfed;
  }
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #fff;
  &:hover {
    border-left-color: #306b99;
  }
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #fff;
  &:hover {
    left: 10px;
    border-right-color: #306b99;
  }
}

.mui-date-picker {
  width: calc(100% - 5px);
  input {
    font-family: 'Ubuntu', sans-serif !important;
    font-size: 11px !important;
  }
  .MuiInput-underline:before {
    border-bottom: 2px solid #c9d8da;
  }
}

.date-picker {
  .inp-addon {
    margin-top: 20px;
  }
}