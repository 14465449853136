.offline {
  // TODO: play what styling looks best
  .base-error {
    border-radius: 15px;
    // padding: 20px;
    margin-top: 30px;
    min-width: 300px;
    background-color: #ffffff;
    margin: 20px;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px,
      rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
    background: transparent;
    box-shadow: none;

    &__error-title {
      padding-top: 10px;
      padding-bottom: 20px;
      color: #fba92c;
      color: #6f6f6f;
    }

    &__close-button.MuiIconButton-root {
      margin-top: -8px;
      margin-left: 10px;
    }

    &__error-info {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.43;
      margin-bottom: 30px;
      color: #6f6f6f;
      font-size: 14px;
    }
    &__button-refresh {
      text-align: center;
      background-color: 4091cf;
      color: #ffffff;
    }
  }
}
