.navbar {
  display: flex;
  text-align: center;
  align-items: center;
  margin: 20px 0;
  transition: 1s;
  .back-btn-container {
    flex-basis: 15%;
  }
  .btn-back {
    background-color: transparent;
  }
  .header {
    flex-basis: 70%;
    font-size: 24px;
    color: #4091cf;
    cursor: default;
  }
  .toggle-menu {
    flex-basis: 15%;
    background-color: transparent;
    border: 0;
    font-size: 28px;
    color: #7dcfed;
    img {
      width: 25px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.menu-content--icon {
  width: 24px;
  height: 24px;
  margin: 14px 0;
}
