.btn {
  width: 100%;
  border-radius: 25px;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  border: 0;
  padding: 15px 0;
  margin-bottom: 2px;
  display: block;
  cursor: pointer;
}
.btn-small {
  padding: 10px 15px;
  width: auto;
}
.btn-login,
.btn-primary {
  position: relative;
  background-color: #4091cf;

  &:hover {
    background-color: darken(#4091cf, 20%);
  }
}
.btn-success {
  background-color: #4f8055;
}
.btn-inverse {
  color: #4091cf;
  background-color: white;
  border: 1px solid #4091cf;

  &:hover {
    background-color: #4091cf;
    color: white;
  }
}
.btn-primary:disabled,
.btn-primary[disabled] {
  background-color: lighten(#4091cf, 20%); /* TODO: more specific color */
  cursor: not-allowed;
}
.btn-inverse:disabled,
.btn-inverse[disabled] {
  background-color: lightgray; /* TODO: more specific color */
  color: gray;
  cursor: not-allowed;
  border-color: gray;

  &:hover {
    color: gray;
  }
}
.btn-secondary {
  background-color: transparent;
  border-radius: 0;
  border-bottom: 1px solid #7dcfed;
  color: #000;
}
.btn-secondary.active {
  color: #4091cf;
}
.btn-secondary:disabled,
.btn-secondary[disabled] {
  background-color: lightgray; /* TODO: more specific color */
  cursor: not-allowed;
}
.btn-login:disabled,
.btn-login[disabled] {
  background-color: #badaff;
}
.btn-login::after {
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  content: "\f35a";
  position: absolute;
  left: 10px;
  bottom: 10px;
  font-size: 26px;
}
.btn-logout {
  background-color: #4091cf;
  margin-right: 20px;
  height: 42px;
}
.btn-logout::after {
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  content: "\f2f5";
  position: absolute;
  left: 10px;
  bottom: 10px;
  font-size: 26px;
}

.btn-back img {
  width: 20px;
  height: 28px;
  padding: 0;
}
