.logo-container {
  height: 200px;
  padding: 50px;
  text-align: center;
  background-color: #fff;
}
.logo-image {
  object-fit: contain;
  object-position: center center;
  width: 100%;
  height: 100%;
}

.edoc-logo {
  position: absolute;
  bottom: 15px;
  filter: grayscale(100%);
  left: 50%;
  transform: translate(-50%, -50%);
}
