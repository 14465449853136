.d-flex {
  display: flex;
}
.align-items--center {
  align-items: center;
}
.justify--center {
  justify-content: center;
}
.justify--between {
  justify-content: space-between;
}
.direction--column {
  flex-direction: column;
}
.align-items--start {
  align-items: flex-start;
}
.align-items--end {
  align-items: flex-end;
}
.align-items--center {
  align-items: center;
}
