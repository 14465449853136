.photo-uploader {
  border-bottom: 2px solid #c9d8da;

  input {
    user-select: none;
  }

  @media screen and (max-width: 400px) {
    width: 100%;
  }

  span {
    font-size: 14px;
  }
  p {
    font-size: 12px;
  }

  &__control {
    padding: 0 25px;
    .form-error__text {
      margin-left: calc(5% + 35px);
    }
    width: 100%;

    @media screen and (max-width: 400px) {
      .form-control {
        width: 100%;
        display: flex;
        margin-right: 0;
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .MuiListItem-gutters {
        padding: 0;
      }
    }
  }

  .photo {
    max-height: 84px;
    min-height: 84px;
    max-width: 79px;
    min-width: 79px;

    &__list {
      display: flex;
      margin-bottom: 35px;
      width: 100%;
    }

    &__wrapper {
      display: flex;
      position: relative;
      flex-direction: column;
      padding: 5px;
    }

    &__delete-text {
      color: #e50a0a;
      font-size: 12px;
      text-decoration: underline;
      cursor: pointer;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__add-button {
      margin-top: 15px;
      margin-left: auto;
      width: auto;
      padding: 0;
    }
  }
}
