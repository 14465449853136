h1 {
  font-size: 24px;
  color: #4091cf;
}

.text-block {
  background-color: white;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 15px;
  width: 100%;
  color: #6f6f6f;
  font-size: 15px;
  font-weight: 100;
  p {
    margin: 10px 0 0;
  }
  a {
    color: #4091cf;
    text-decoration: underline;
  }
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-success {
  color: #94D336;
}

.text-info {
  color: #92D3EE;
}

.text-disabled {
  color: #D7D8DA;
}
