.accept-tos {
  background-color: #ffffff;
  padding: 15px;
  margin-top: 30px;
  border-radius: 15px;

  &__title {
    text-align: center;
    padding-top: 10px;
    margin-bottom: 30px;
    color: #4091cf;
  }
  &__content {
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    margin-bottom: 30px;
    color: #6f6f6f;;
  }
  &__link {
    text-decoration: underline;
    color: #4091cf;
    padding-left: 5px;
  }
  &__failed {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #fba92c;
  }
  &__actions {
    display: flex;
  }
  &__decline-button {
    margin-right: 5px;
    background-color: #ffffff;
    border: 1px solid #4091cf;
    color: #4091cf;
  }
  &__accept-button {
    margin-left: 5px;
  }
}