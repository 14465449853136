.item-list {
  display: flex;
  flex-direction: column;
  font-family: "Ubuntu", sans-serif;
  .item {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    align-items: center;
    margin: 5px 0;
    position: relative;
    z-index: 0;

    .item-details {
      text-align: left;
      color: #838383;
      padding: 0 10px 10px;
      width: 100%;

      &.has-number {
        padding-left: 70px;
      }
    }

    .item-top-row {
      display: flex;
      width: 100%;
      align-items: center;
    }

    &.item-disabled,
    &.item-completed {
      pointer-events: none;
    }
  }
}

.disabled-item-overlay {
  display: none;
}

.item-list {
  .item.item-disabled .disabled-item-overlay {
    position: absolute;
    display: block;
    background-color: rgba(232, 232, 232, 0.5);
    border-radius: 10px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 2;
  }
  .item-number {
    display: flex;
    background-color: #f4f7fa;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 10px;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    font-weight: 600;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .item .title {
    text-align: left;
    flex: 60;
    padding: 10px 0;
    color: #4091cf;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    &.has-number {
      margin: 0 60px;

      &.has-details {
        margin: 0 60px;
      }
    }
    &.has-details {
      margin: 2px 0;
      padding: 0;
    }
  }
}

.item .title small {
  display: block;
  color: #838383;
}

.item-list .item {
  padding: 0;
  .title {
    padding: 10px;
  }
  .title.has-details {
    padding: 10px;
  }

  .info {
    padding: 10px 5px;
    width: calc(2 * 5px + 25px); /* fixed size: padding + content */
    cursor: default;

    &:empty {
      visibility: hidden;
    }

    span {
      color: #ffb619;
      border: 2px solid #ffb619;
      border-radius: 50%;
      padding: 1px 8px;
      font-weight: bold;
    }
  }
  .icon {
    width: calc(2 * 5px + 25px); /* fixed size: padding + img */

    &:empty {
      visibility: hidden;
    }

    display: flex;
    justify-content: center;

    img {
      width: 25px;
      height: 25px;
      padding: 0;
      display: inline-block;
    }
  }
  .count {
    padding: 5px 15px;
    margin-right: 5px; /* icons next to this have padding within them to present space, this needs margin to present space outside the border */
    border: 2px solid #afe1f4;
    border-radius: 10px;
    color: #afe1f4;
    font-weight: bold;
    cursor: default;

    &:empty {
      visibility: hidden;
    }

    @media screen and (max-width: 600px) {
      &:empty {
        display: none;
      }
    }
  }
  .dropdown {
    padding: 0 10px 0 5px;
    width: calc(5px + 10px + 12px); /* fixed size: padding + img */
    color: #a6b9c0;
    font-size: 28px;
    &:hover {
      cursor: pointer;
      // TODO: create common button component
    }
    display: flex;
    justify-content: center;
  }
}

.item-slidedown .buttons-block {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: -15px;
  .button__wrapper {
    flex-basis: 49.5%;
    background-color: #f4f7fa;
    padding: 15px;
    border: 0;
    color: #6f6f6f;
    display: flex;
    justify-content: center;
    align-items: center;
    &.button-left {
      border-bottom-left-radius: 10px;
    }
    &.button-right {
      border-bottom-right-radius: 10px;
    }
  }
  button {
    background-color: #f4f7fa;
    border: 0;
    color: #6f6f6f;
    padding-left: 35px;
  }
}

.buttons-block .btn-yes {
  position: relative;
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
}

.btn-yes::before {
  content: "\F058";
  font-family: "Font Awesome 5 Free", sans-serif;
  position: absolute;
  left: 10px;
  top: -2px;
  color: #94d342;
  font-size: 20px;
  font-weight: 900;
}

.buttons-block .btn-no {
  position: relative;
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
}

.btn-no::before {
  content: "\f057";
  font-family: "Font Awesome 5 Free", sans-serif;
  position: absolute;
  left: 10px;
  top: -2px;
  color: #e83c3c;
  font-size: 20px;
  font-weight: 900;
}

.item-slidedown .photo-block {
  background-color: #a9c0c8;
  height: auto;
  width: 88%;
  margin: 0 auto;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
  flex-wrap: wrap;
}

.photo-block .photo-item {
  flex-basis: 20%;
  text-align: center;
  position: relative;
}

.photo-item-delete {
  position: absolute;
  top: -7px;
  right: -5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid #b63333;
  color: #b63333;
  background-color: #fff;
  font-weight: bold;
  font-size: 12px;
}

.photo-block img {
  border-radius: 10px;
  width: 50px;
  height: 50px;
}

.item-slidedown .photo-block {
  &:before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    left: -20px;
    top: -10px;
    border-width: 10px;
    border-color: #a9c0c8 #a9c0c8 transparent transparent;
    border-style: solid;
    border-radius: 0 65%;
    z-index: -1;
  }
  &:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    right: -20px;
    top: -10px;
    border-width: 10px;
    border-color: #a9c0c8 transparent transparent #a9c0c8;
    border-style: solid;
    border-radius: 65% 0;
    z-index: -1;
  }
}

.add-photo-block {
  width: 75%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  button {
    width: 100%;
    padding: 15px;
    background-color: #4091cf;
    border: 0;
    color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 14px;
    }
    i {
      font-size: 20px;
      margin-right: 5px;
    }
    &:before {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      left: -20px;
      top: -10px;
      border-width: 10px;
      border-color: #4091cf #4091cf transparent transparent;
      border-style: solid;
      border-radius: 0 65%;
      z-index: -1;
    }
    &:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      right: -20px;
      top: -10px;
      border-width: 10px;
      border-color: #4091cf transparent transparent #4091cf;
      border-style: solid;
      border-radius: 65% 0;
      z-index: -1;
    }
  }
}

.item-content {
  background-color: #fff;
  margin: -10px 0 10px;
  padding: 15px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.list-item-controls {
  margin-bottom: 10px;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  a {
    width: 100%;
    padding: 10px 0;
    background-color: #a9c0c8;
    color: #fff;
    border-bottom: 1px solid #b5cad2;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    text-decoration: none;
  }
  .active-adhoc-control {
    background-color: #4f8055;
    color: white;
  }
  a {
    &:last-of-type {
      box-shadow: inset 0 -5px 15px -10px rgba(0, 0, 0, 0.75);
    }
    &:first-of-type {
      box-shadow: inset 0 5px 12px -10px rgba(0, 0, 0, 0.75);
    }
    i {
      flex-basis: 15%;
      text-align: center;
      font-size: 20px;
    }
    span {
      flex-basis: 85%;
      text-align: left;
    }
  }
}

.list-item-footer {
  width: 100%;
  background-color: #f4f7fa;
  margin-top: -10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  z-index: 1;
  padding: 10px;
  display: inline-flex;
  .add-date {
    flex-basis: 85%;
    display: flex;
    justify-content: center;
    align-content: center;
  }
}

.add-date button {
  border: 0;
  background-color: transparent;
  color: #4091cf;
  font-weight: bold;
  font-size: 15px;
  margin-left: 60px;
  span {
    margin-left: 5px;
  }
}

.list-item-footer .btn-approve-block {
  flex-basis: 15%;
  align-self: center;
}

.btn-approve-block button {
  padding: 10px 0;
  background-color: #4091cf;
  color: #fff;
}

.icon-gps {
  color: #4091cf;
  font-size: 23px;
  width: calc(2 * 5px + 23px); /* fixed size: padding + icon */

  &:empty {
    visibility: hidden;
  }
}

.item-list {
  .item {
    .title {
      padding: 14px 14px 14px 10px;
    }
    .title.has-details {
      padding: 14px 7px 7px 10px;
      white-space: normal;
    }
    @media screen and (max-width: 600px) {
      .title {
        min-width: 90px;
        margin-right: 0 !important;
      }
      .title.has-details {
        min-width: 90px;
        margin-right: 0 !important;
      }
    }
  }
}
