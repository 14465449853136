.snackbars {
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px;

  .snackbar {
    border: 1px solid #008000;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    margin-bottom: 20px;
    background-color: #f9f9f9;

    &--success {
      border-color: #008000;
    }
    &--error {
      border-color: #ff0000;
    }

    &__content {
      padding: 5px 0;
    }
    &__title {
      color: #000000;
      opacity: 0.85;
    }
    &__body {
      font-size: 12px;
      padding-top: 10px;
      color: #838383;
    }
    &__actions {
      margin-left: 10px;
      align-self: center;
      justify-self: center;
      .MuiIconButton-root {
        padding: 5px;
        &.success {
          color: #008000;
        }
        &.error {
          color: #ff0000;
        }
      }
    }
  }
}
