.notes-slide {
  width: 100%;
  min-height: 40px;
  &__button {
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .btn-secondary {
    width: initial;
  }
}
