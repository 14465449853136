.alert {
  font-size: 15px;
  color: #721c24;
  padding: 15px;
  margin: 15px 10px 0;
  border-radius: 5px;
  position: relative;

  p {
    color: #721c24;
  }

  ul {
    li {
      list-style: none;
    }
  }

  .close-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-60%);
    font-size: 18px;
    color: white;
    font-weight: bold;
    line-height: normal;
    cursor: pointer;
  }
}
.alert-danger {
  background-color: rgba(255, 0, 0, 0.3);
  color: #fff;
}
.alert-warning {
  background-color: #fff3cd;
  color: #856404;
}
