/*
  Login form
 */

.form-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-form {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  width: 100%;
  .input-group {
    flex-basis: 100%;
    margin: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .inp {
      width: 100%;
    }
    &.has-icon {
      .inp {
        flex-basis: 88%;
      }
      .inp-addon {
        width: 30px;
        height: 30px;
        flex-basis: 10%;
      }
    }
  }
}

.input-group {
  display: flex;
  align-items: center;
}

.inp-error {
  color: red;
  display: none;
  margin-bottom: 1em;

  &.inp-error--visible {
    display: block;
  }
}

.inp-addon {
  width: 25px;
  height: 25px;
}

.input-group.has-icon {
  .inp,
  select {
    width: 75%;
  }
  .inp-addon {
    margin-right: 10px;
  }
}

.form-group {
  display: flex;
  align-items: center;
}

.form-centered {
  justify-content: space-evenly;
}

.form-vertical {
  flex-direction: column;
  align-items: flex-start;
  .input-group {
    width: 100%;
  }
}

.lang-block {
  display: flex;
  align-items: center;
  width: 100%;
  button:focus {
    background-color: transparent;
  }
  &.active button {
    font-weight: bold;
    color: #4091cf;
    border-color: #4091cf;
  }
  .lang-icon {
    padding: 15px;
  }
}

.custom-fields--block {
  display: block;
  width: 100%;
  padding: 0 25px;
  .inp {
    width: 100%;
  }
  label {
    color: #6f6f6f;
    font-size: 13px;
    display: block;
    font-weight: bold;
    text-align: left;
  }
}

.submit-block {
  background-color: #f4f7fa;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 35px 25px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.reminder-form .inp {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 75%;
  .inp {
    width: 100% !important;
  }
}

.datepicker-block {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 0 auto;
}

.actions-bar {
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  margin: 1em 0;

  .actions-bar-item {
    flex: 1;

    .btn {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
.audit__perform-all--form {
  select {
    flex: 2;
  }
  button {
    flex: 1;
  }
}
