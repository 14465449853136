.notes {
  align-self: center;
  width: calc(75% + 35px);
  padding-top: 20px;
  span {
    font-size: 14px;
  }
  p {
    font-size: 12px;
  }
}
.notes-dialog {
  textarea {
    border-radius: 0;
  }
  .mt-3 {
    margin-top: 0;
  }
  .inp-addon {
    margin-top: 20px;
  }
  .MuiInput-underline:before {
    border-bottom: 2px solid #c9d8da;
  }
}
