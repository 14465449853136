/*
  Burger menu default styles
 */
.bm-burger-button {
  display: none;
}
.bm-cross-button {
  display: none;
}
.bm-cross {
  background: #bdc3c7;
}
.bm-menu-wrap {
  width: 80vw;
  top: 0;
}
.bm-menu {
  background: #fff;
  padding: 2.5em 0;
  font-size: 1.15em;
  position: relative;
}
.bm-morph-shape {
  fill: #373a47;
}
.bm-item-list {
  color: #b8b7ad;
  height: auto !important;
}
.bm-item {
  display: inline-block;
}
.bm-overlay {
  background: unset;
}
.menu-toggle-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #7dcfed;
  font-size: 25px;
  border: 0;
  background-color: transparent;
  &:hover {
    cursor: pointer;
  }
}
.menu-toggle-icon .close-icon {
  width: 20px;
}
.bm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}

.user-info-block-container {
  background-color: #f4f7fa;
  padding: 30px 15px;
  margin: 20px 0 20px 0;
  width: 100%;
  color: #6f6f6f;
}
.user-info-block {
  display: flex !important;
  justify-content: center;
  align-content: center;
}
.user-info-block i {
  color: #7dcfed;
  font-size: 40px;
}
.user-info-block span {
  align-self: center;
  margin-left: 10px;
  font-size: 15px;
}
.user-info-block-email {
  cursor: default;
}
.user-password-block a {
  display: flex !important;
  justify-content: center;
  align-content: center;
}
.user-password-block span {
  align-self: center;
  margin-left: 10px;
  font-size: 12px;
}
.menu-links-block {
  display: flex;
  flex-direction: column;
  padding: 25px 40px;
  margin-bottom: 50px;
}
.menu-links-block a {
  display: inline-flex;
  width: 100%;
  text-decoration: none;
  color: #7dcfed;
}
.menu-links-block i {
  align-self: center;
  flex-basis: 10%;
  font-size: 20px;
}
.menu-links-block span {
  margin-left: 10px;
  padding: 15px 0;
  flex-basis: 90%;
  border-bottom: 2px solid #edeff2;
  color: #4091cf;
}
.menu-logout-block {
  bottom: 0;
  left: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #f4f7fa;
  height: 80px;
  position: fixed;
  width: 300px;
  right: 0;
  margin-left: auto;
}

.icon-help {
  position: fixed;
  bottom: 5px;
  right: 5px;
  background-image: url('../../images/icons/icon-help.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 40px;
  height: 40px;
}

.login-forgot-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  a {
    font-size: 12px;
  }
}
