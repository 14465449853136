.mt-1 {
  margin-top: 5px;
}
.mt-2 {
  margin-top: 10px;
}
.mt-3 {
  margin-top: 15px;
}
.mt-4 {
  margin-top: 20px;
}
.mt-5 {
  margin-top: 25px;
}
.mb-1 {
  margin-bottom: 5px;
}
.mb-2 {
  margin-bottom: 10px;
}
.mb-3 {
  margin-bottom: 15px;
}
.mb-4 {
  margin-bottom: 20px;
}
.mb-5 {
  margin-bottom: 25px;
}
.ml-1 {
  margin-left: 5px;
}
.ml-2 {
  margin-left: 10px;
}
.ml-3 {
  margin-left: 15px;
}
.ml-4 {
  margin-left: 20px;
}
.ml-5 {
  margin-left: 25px;
}
.mr-1 {
  margin-right: 5px;
}
.mr-2 {
  margin-right: 10px;
}
.mr-3 {
  margin-right: 15px;
}
.mr-4 {
  margin-right: 20px;
}
.mr-5 {
  margin-right: 25px;
}
.pb-1 {
  padding-bottom: 5px;
}
.pb-2 {
  padding-bottom: 10px;
}
.pb-3 {
  padding-bottom: 15px;
}
.pb-4 {
  padding-bottom: 20px;
}
.pb-5 {
  padding-bottom: 25px;
}
.py-1 {
  padding: 5px 0;
}
.py-2 {
  padding: 10px 0;
}
.py-3 {
  padding: 15px 0;
}
.py-4 {
  padding: 20px 0;
}
.py-5 {
  padding: 25px 0;
}
.mx-1 {
  margin: 0 5px;
}
.mx-2 {
  margin: 0 10px;
}
.mx-3 {
  margin: 0 15px;
}
.mx-4 {
  margin: 0 20px;
}
.mx-5 {
  margin: 0 25px;
}
.w-100 {
  width: 100% !important;
}
