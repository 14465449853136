.error-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 80px 0 0;
  img {
    width: 125px;
    margin: 0 auto 50px;
  }
  .error-button {
    background-color: #f4f7fa;
    padding: 80px 25px;
  }
}
