.lds-loading-container {
  margin: 25px 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 50px;
  height: 50px;
}

.loading-text {
  padding: 10px 0;
  color: #7dcfed;
  font-weight: bold;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 25px;
  height: 25px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #7dcfed;
  border-color: #7dcfed transparent #7dcfed transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
