.inp {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #cad8da;
  height: 35px;
}

.checkbox {
  border-radius: 15px;
  background-color: #6f6f6f;
  border: 0;
}

label {
  color: #6f6f6f;
}
