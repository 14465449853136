@charset "UTF-8";
@import "~react-datepicker/dist/react-datepicker.css";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  outline: 0;
}

p {
  color: #6f6f6f;
  padding: 3px 0;
}

body,
html {
  height: 100%;
  background-color: #e4eff1;
  position: relative;
  z-index: 0;
}

input {
  outline: 0;
}

.page-wrapper {
  position: relative;
  min-height: calc(100vh - 35px);
  padding-bottom: 85px;
  max-width: 800px;
  margin: 0 auto;
}

.content {
  border-top: 2px solid #cad8da;
  width: 100%;
  height: 100%;
  padding: 0 10px;
}

a {
  text-decoration: none;
  color: inherit;
}

section {
  margin: 0 15px;
}

textarea {
  width: 100%;
  height: 100px;
  border: 2px solid #cad8da;
  padding: 10px;
  border-radius: 15px;
  max-width: 100%;
}

select {
  height: 30px;
  color: #6f6f6f;
  border: 0;
  border-bottom: 2px solid #ecf0f0;
  width: 100%;
}
select:disabled {
  color: lightgray;
  cursor: not-allowed;
}

.alert {
  font-size: 15px;
  color: #721c24;
  padding: 15px;
  margin: 15px 10px 0;
  border-radius: 5px;
  position: relative;
}
.alert p {
  color: #721c24;
}
.alert ul li {
  list-style: none;
}
.alert .close-icon {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-60%);
  font-size: 18px;
  color: white;
  font-weight: bold;
  line-height: normal;
  cursor: pointer;
}

.alert-danger {
  background-color: rgba(255, 0, 0, 0.3);
  color: #fff;
}

.alert-warning {
  background-color: #fff3cd;
  color: #856404;
}

.btn {
  width: 100%;
  border-radius: 25px;
  color: #fff;
  text-transform: uppercase;
  position: relative;
  border: 0;
  padding: 15px 0;
  margin-bottom: 2px;
  display: block;
  cursor: pointer;
}

.btn-small {
  padding: 10px 15px;
  width: auto;
}

.btn-login,
.btn-primary {
  position: relative;
  background-color: #4091cf;
}
.btn-login:hover,
.btn-primary:hover {
  background-color: #225b87;
}

.btn-success {
  background-color: #4f8055;
}

.btn-inverse {
  color: #4091cf;
  background-color: white;
  border: 1px solid #4091cf;
}
.btn-inverse:hover {
  background-color: #4091cf;
  color: white;
}

.btn-primary:disabled,
.btn-primary[disabled] {
  background-color: #92c0e3;
  /* TODO: more specific color */
  cursor: not-allowed;
}

.btn-inverse:disabled,
.btn-inverse[disabled] {
  background-color: lightgray;
  /* TODO: more specific color */
  color: gray;
  cursor: not-allowed;
  border-color: gray;
}
.btn-inverse:disabled:hover,
.btn-inverse[disabled]:hover {
  color: gray;
}

.btn-secondary {
  background-color: transparent;
  border-radius: 0;
  border-bottom: 1px solid #7dcfed;
  color: #000;
}

.btn-secondary.active {
  color: #4091cf;
}

.btn-secondary:disabled,
.btn-secondary[disabled] {
  background-color: lightgray;
  /* TODO: more specific color */
  cursor: not-allowed;
}

.btn-login:disabled,
.btn-login[disabled] {
  background-color: #badaff;
}

.btn-login::after {
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  content: "";
  position: absolute;
  left: 10px;
  bottom: 10px;
  font-size: 26px;
}

.btn-logout {
  background-color: #4091cf;
  margin-right: 20px;
  height: 42px;
}

.btn-logout::after {
  font-family: "Font Awesome 5 Free", sans-serif;
  font-weight: 900;
  content: "";
  position: absolute;
  left: 10px;
  bottom: 10px;
  font-size: 26px;
}

.btn-back img {
  width: 20px;
  height: 28px;
  padding: 0;
}

.react-datepicker {
  border: 1px solid #7dcfed;
}

.react-datepicker__header {
  background-color: #7dcfed;
}

.react-datepicker__current-month, .react-datepicker__day-name, .react-datepicker-time__header {
  color: #fff;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range {
  border-radius: 50%;
  background-color: #7dcfed;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover {
  background-color: #7dcfed;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
  border-top: none;
  border-bottom-color: #7dcfed;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #7dcfed;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #fff;
}
.react-datepicker__navigation--next:hover {
  border-left-color: #306b99;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #fff;
}
.react-datepicker__navigation--previous:hover {
  left: 10px;
  border-right-color: #306b99;
}

.mui-date-picker {
  width: calc(100% - 5px);
}
.mui-date-picker input {
  font-family: "Ubuntu", sans-serif !important;
  font-size: 11px !important;
}
.mui-date-picker .MuiInput-underline:before {
  border-bottom: 2px solid #c9d8da;
}

.date-picker .inp-addon {
  margin-top: 20px;
}

.error-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 80px 0 0;
}
.error-container img {
  width: 125px;
  margin: 0 auto 50px;
}
.error-container .error-button {
  background-color: #f4f7fa;
  padding: 80px 25px;
}

.d-flex {
  display: flex;
}

.align-items--center {
  align-items: center;
}

.justify--center {
  justify-content: center;
}

.justify--between {
  justify-content: space-between;
}

.direction--column {
  flex-direction: column;
}

.align-items--start {
  align-items: flex-start;
}

.align-items--end {
  align-items: flex-end;
}

.align-items--center {
  align-items: center;
}

/*
  Login form
 */
.form-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-form {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  width: 100%;
}
.login-form .input-group {
  flex-basis: 100%;
  margin: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.login-form .input-group .inp {
  width: 100%;
}
.login-form .input-group.has-icon .inp {
  flex-basis: 88%;
}
.login-form .input-group.has-icon .inp-addon {
  width: 30px;
  height: 30px;
  flex-basis: 10%;
}

.input-group {
  display: flex;
  align-items: center;
}

.inp-error {
  color: red;
  display: none;
  margin-bottom: 1em;
}
.inp-error.inp-error--visible {
  display: block;
}

.inp-addon {
  width: 25px;
  height: 25px;
}

.input-group.has-icon .inp,
.input-group.has-icon select {
  width: 75%;
}
.input-group.has-icon .inp-addon {
  margin-right: 10px;
}

.form-group {
  display: flex;
  align-items: center;
}

.form-centered {
  justify-content: space-evenly;
}

.form-vertical {
  flex-direction: column;
  align-items: flex-start;
}
.form-vertical .input-group {
  width: 100%;
}

.lang-block {
  display: flex;
  align-items: center;
  width: 100%;
}
.lang-block button:focus {
  background-color: transparent;
}
.lang-block.active button {
  font-weight: bold;
  color: #4091cf;
  border-color: #4091cf;
}
.lang-block .lang-icon {
  padding: 15px;
}

.custom-fields--block {
  display: block;
  width: 100%;
  padding: 0 25px;
}
.custom-fields--block .inp {
  width: 100%;
}
.custom-fields--block label {
  color: #6f6f6f;
  font-size: 13px;
  display: block;
  font-weight: bold;
  text-align: left;
}

.submit-block {
  background-color: #f4f7fa;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 35px 25px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.reminder-form .inp {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 75%;
}
.react-datepicker-wrapper .inp {
  width: 100% !important;
}

.datepicker-block {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: 0 auto;
}

.actions-bar {
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  margin: 1em 0;
}
.actions-bar .actions-bar-item {
  flex: 1;
}
.actions-bar .actions-bar-item .btn {
  margin-left: auto;
  margin-right: auto;
}

.audit__perform-all--form select {
  flex: 2;
}
.audit__perform-all--form button {
  flex: 1;
}

.inp {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #cad8da;
  height: 35px;
}

.checkbox {
  border-radius: 15px;
  background-color: #6f6f6f;
  border: 0;
}

label {
  color: #6f6f6f;
}

.item-list {
  display: flex;
  flex-direction: column;
  font-family: "Ubuntu", sans-serif;
}
.item-list .item {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  align-items: center;
  margin: 5px 0;
  position: relative;
  z-index: 0;
}
.item-list .item .item-details {
  text-align: left;
  color: #838383;
  padding: 0 10px 10px;
  width: 100%;
}
.item-list .item .item-details.has-number {
  padding-left: 70px;
}
.item-list .item .item-top-row {
  display: flex;
  width: 100%;
  align-items: center;
}
.item-list .item.item-disabled, .item-list .item.item-completed {
  pointer-events: none;
}

.disabled-item-overlay {
  display: none;
}

.item-list .item.item-disabled .disabled-item-overlay {
  position: absolute;
  display: block;
  background-color: rgba(232, 232, 232, 0.5);
  border-radius: 10px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  z-index: 2;
}
.item-list .item-number {
  display: flex;
  background-color: #f4f7fa;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 10px;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  font-weight: 600;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.item-list .item .title {
  text-align: left;
  flex: 60;
  padding: 10px 0;
  color: #4091cf;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.item-list .item .title.has-number {
  margin: 0 60px;
}
.item-list .item .title.has-number.has-details {
  margin: 0 60px;
}
.item-list .item .title.has-details {
  margin: 2px 0;
  padding: 0;
}

.item .title small {
  display: block;
  color: #838383;
}

.item-list .item {
  padding: 0;
}
.item-list .item .title {
  padding: 10px;
}
.item-list .item .title.has-details {
  padding: 10px;
}
.item-list .item .info {
  padding: 10px 5px;
  width: calc(2 * 5px + 25px);
  /* fixed size: padding + content */
  cursor: default;
}
.item-list .item .info:empty {
  visibility: hidden;
}
.item-list .item .info span {
  color: #ffb619;
  border: 2px solid #ffb619;
  border-radius: 50%;
  padding: 1px 8px;
  font-weight: bold;
}
.item-list .item .icon {
  width: calc(2 * 5px + 25px);
  /* fixed size: padding + img */
  display: flex;
  justify-content: center;
}
.item-list .item .icon:empty {
  visibility: hidden;
}
.item-list .item .icon img {
  width: 25px;
  height: 25px;
  padding: 0;
  display: inline-block;
}
.item-list .item .count {
  padding: 5px 15px;
  margin-right: 5px;
  /* icons next to this have padding within them to present space, this needs margin to present space outside the border */
  border: 2px solid #afe1f4;
  border-radius: 10px;
  color: #afe1f4;
  font-weight: bold;
  cursor: default;
}
.item-list .item .count:empty {
  visibility: hidden;
}
@media screen and (max-width: 600px) {
  .item-list .item .count:empty {
    display: none;
  }
}
.item-list .item .dropdown {
  padding: 0 10px 0 5px;
  width: calc(5px + 10px + 12px);
  /* fixed size: padding + img */
  color: #a6b9c0;
  font-size: 28px;
  display: flex;
  justify-content: center;
}
.item-list .item .dropdown:hover {
  cursor: pointer;
}

.item-slidedown .buttons-block {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: -15px;
}
.item-slidedown .buttons-block .button__wrapper {
  flex-basis: 49.5%;
  background-color: #f4f7fa;
  padding: 15px;
  border: 0;
  color: #6f6f6f;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-slidedown .buttons-block .button__wrapper.button-left {
  border-bottom-left-radius: 10px;
}
.item-slidedown .buttons-block .button__wrapper.button-right {
  border-bottom-right-radius: 10px;
}
.item-slidedown .buttons-block button {
  background-color: #f4f7fa;
  border: 0;
  color: #6f6f6f;
  padding-left: 35px;
}

.buttons-block .btn-yes {
  position: relative;
  font-size: 14px;
}
.buttons-block .btn-yes:hover {
  cursor: pointer;
}

.btn-yes::before {
  content: "";
  font-family: "Font Awesome 5 Free", sans-serif;
  position: absolute;
  left: 10px;
  top: -2px;
  color: #94d342;
  font-size: 20px;
  font-weight: 900;
}

.buttons-block .btn-no {
  position: relative;
  font-size: 14px;
}
.buttons-block .btn-no:hover {
  cursor: pointer;
}

.btn-no::before {
  content: "";
  font-family: "Font Awesome 5 Free", sans-serif;
  position: absolute;
  left: 10px;
  top: -2px;
  color: #e83c3c;
  font-size: 20px;
  font-weight: 900;
}

.item-slidedown .photo-block {
  background-color: #a9c0c8;
  height: auto;
  width: 88%;
  margin: 0 auto;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
  flex-wrap: wrap;
}

.photo-block .photo-item {
  flex-basis: 20%;
  text-align: center;
  position: relative;
}

.photo-item-delete {
  position: absolute;
  top: -7px;
  right: -5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid #b63333;
  color: #b63333;
  background-color: #fff;
  font-weight: bold;
  font-size: 12px;
}

.photo-block img {
  border-radius: 10px;
  width: 50px;
  height: 50px;
}

.item-slidedown .photo-block:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: -20px;
  top: -10px;
  border-width: 10px;
  border-color: #a9c0c8 #a9c0c8 transparent transparent;
  border-style: solid;
  border-radius: 0 65%;
  z-index: -1;
}
.item-slidedown .photo-block:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  right: -20px;
  top: -10px;
  border-width: 10px;
  border-color: #a9c0c8 transparent transparent #a9c0c8;
  border-style: solid;
  border-radius: 65% 0;
  z-index: -1;
}

.add-photo-block {
  width: 75%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 15px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.add-photo-block button {
  width: 100%;
  padding: 15px;
  background-color: #4091cf;
  border: 0;
  color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-photo-block button span {
  font-size: 14px;
}
.add-photo-block button i {
  font-size: 20px;
  margin-right: 5px;
}
.add-photo-block button:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  left: -20px;
  top: -10px;
  border-width: 10px;
  border-color: #4091cf #4091cf transparent transparent;
  border-style: solid;
  border-radius: 0 65%;
  z-index: -1;
}
.add-photo-block button:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  right: -20px;
  top: -10px;
  border-width: 10px;
  border-color: #4091cf transparent transparent #4091cf;
  border-style: solid;
  border-radius: 65% 0;
  z-index: -1;
}

.item-content {
  background-color: #fff;
  margin: -10px 0 10px;
  padding: 15px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.list-item-controls {
  margin-bottom: 10px;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}
.list-item-controls a {
  width: 100%;
  padding: 10px 0;
  background-color: #a9c0c8;
  color: #fff;
  border-bottom: 1px solid #b5cad2;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  text-decoration: none;
}
.list-item-controls .active-adhoc-control {
  background-color: #4f8055;
  color: white;
}
.list-item-controls a:last-of-type {
  box-shadow: inset 0 -5px 15px -10px rgba(0, 0, 0, 0.75);
}
.list-item-controls a:first-of-type {
  box-shadow: inset 0 5px 12px -10px rgba(0, 0, 0, 0.75);
}
.list-item-controls a i {
  flex-basis: 15%;
  text-align: center;
  font-size: 20px;
}
.list-item-controls a span {
  flex-basis: 85%;
  text-align: left;
}

.list-item-footer {
  width: 100%;
  background-color: #f4f7fa;
  margin-top: -10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  z-index: 1;
  padding: 10px;
  display: inline-flex;
}
.list-item-footer .add-date {
  flex-basis: 85%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.add-date button {
  border: 0;
  background-color: transparent;
  color: #4091cf;
  font-weight: bold;
  font-size: 15px;
  margin-left: 60px;
}
.add-date button span {
  margin-left: 5px;
}

.list-item-footer .btn-approve-block {
  flex-basis: 15%;
  align-self: center;
}

.btn-approve-block button {
  padding: 10px 0;
  background-color: #4091cf;
  color: #fff;
}

.icon-gps {
  color: #4091cf;
  font-size: 23px;
  width: calc(2 * 5px + 23px);
  /* fixed size: padding + icon */
}
.icon-gps:empty {
  visibility: hidden;
}

.item-list .item .title {
  padding: 14px 14px 14px 10px;
}
.item-list .item .title.has-details {
  padding: 14px 7px 7px 10px;
  white-space: normal;
}
@media screen and (max-width: 600px) {
  .item-list .item .title {
    min-width: 90px;
    margin-right: 0 !important;
  }
  .item-list .item .title.has-details {
    min-width: 90px;
    margin-right: 0 !important;
  }
}

.lds-loading-container {
  margin: 25px 0 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 50px;
  height: 50px;
}

.loading-text {
  padding: 10px 0;
  color: #7dcfed;
  font-weight: bold;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 25px;
  height: 25px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #7dcfed;
  border-color: #7dcfed transparent #7dcfed transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.logo-container {
  height: 200px;
  padding: 50px;
  text-align: center;
  background-color: #fff;
}

.logo-image {
  object-fit: contain;
  object-position: center center;
  width: 100%;
  height: 100%;
}

.edoc-logo {
  position: absolute;
  bottom: 15px;
  filter: grayscale(100%);
  left: 50%;
  transform: translate(-50%, -50%);
}

/*
  Burger menu default styles
 */
.bm-burger-button {
  display: none;
}

.bm-cross-button {
  display: none;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu-wrap {
  width: 80vw;
  top: 0;
}

.bm-menu {
  background: #fff;
  padding: 2.5em 0;
  font-size: 1.15em;
  position: relative;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-item-list {
  color: #b8b7ad;
  height: auto !important;
}

.bm-item {
  display: inline-block;
}

.bm-overlay {
  background: unset;
}

.menu-toggle-icon {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #7dcfed;
  font-size: 25px;
  border: 0;
  background-color: transparent;
}
.menu-toggle-icon:hover {
  cursor: pointer;
}

.menu-toggle-icon .close-icon {
  width: 20px;
}

.bm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
}

.user-info-block-container {
  background-color: #f4f7fa;
  padding: 30px 15px;
  margin: 20px 0 20px 0;
  width: 100%;
  color: #6f6f6f;
}

.user-info-block {
  display: flex !important;
  justify-content: center;
  align-content: center;
}

.user-info-block i {
  color: #7dcfed;
  font-size: 40px;
}

.user-info-block span {
  align-self: center;
  margin-left: 10px;
  font-size: 15px;
}

.user-info-block-email {
  cursor: default;
}

.user-password-block a {
  display: flex !important;
  justify-content: center;
  align-content: center;
}

.user-password-block span {
  align-self: center;
  margin-left: 10px;
  font-size: 12px;
}

.menu-links-block {
  display: flex;
  flex-direction: column;
  padding: 25px 40px;
  margin-bottom: 50px;
}

.menu-links-block a {
  display: inline-flex;
  width: 100%;
  text-decoration: none;
  color: #7dcfed;
}

.menu-links-block i {
  align-self: center;
  flex-basis: 10%;
  font-size: 20px;
}

.menu-links-block span {
  margin-left: 10px;
  padding: 15px 0;
  flex-basis: 90%;
  border-bottom: 2px solid #edeff2;
  color: #4091cf;
}

.menu-logout-block {
  bottom: 0;
  left: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #f4f7fa;
  height: 80px;
  position: fixed;
  width: 300px;
  right: 0;
  margin-left: auto;
}

.icon-help {
  position: fixed;
  bottom: 5px;
  right: 5px;
  background-image: url("../../images/icons/icon-help.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 40px;
  height: 40px;
}

.login-forgot-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.login-forgot-container a {
  font-size: 12px;
}

.navbar {
  display: flex;
  text-align: center;
  align-items: center;
  margin: 20px 0;
  transition: 1s;
}
.navbar .back-btn-container {
  flex-basis: 15%;
}
.navbar .btn-back {
  background-color: transparent;
}
.navbar .header {
  flex-basis: 70%;
  font-size: 24px;
  color: #4091cf;
  cursor: default;
}
.navbar .toggle-menu {
  flex-basis: 15%;
  background-color: transparent;
  border: 0;
  font-size: 28px;
  color: #7dcfed;
}
.navbar .toggle-menu img {
  width: 25px;
}
.navbar .toggle-menu:hover {
  cursor: pointer;
}

.menu-content--icon {
  width: 24px;
  height: 24px;
  margin: 14px 0;
}

.mt-1 {
  margin-top: 5px;
}

.mt-2 {
  margin-top: 10px;
}

.mt-3 {
  margin-top: 15px;
}

.mt-4 {
  margin-top: 20px;
}

.mt-5 {
  margin-top: 25px;
}

.mb-1 {
  margin-bottom: 5px;
}

.mb-2 {
  margin-bottom: 10px;
}

.mb-3 {
  margin-bottom: 15px;
}

.mb-4 {
  margin-bottom: 20px;
}

.mb-5 {
  margin-bottom: 25px;
}

.ml-1 {
  margin-left: 5px;
}

.ml-2 {
  margin-left: 10px;
}

.ml-3 {
  margin-left: 15px;
}

.ml-4 {
  margin-left: 20px;
}

.ml-5 {
  margin-left: 25px;
}

.mr-1 {
  margin-right: 5px;
}

.mr-2 {
  margin-right: 10px;
}

.mr-3 {
  margin-right: 15px;
}

.mr-4 {
  margin-right: 20px;
}

.mr-5 {
  margin-right: 25px;
}

.pb-1 {
  padding-bottom: 5px;
}

.pb-2 {
  padding-bottom: 10px;
}

.pb-3 {
  padding-bottom: 15px;
}

.pb-4 {
  padding-bottom: 20px;
}

.pb-5 {
  padding-bottom: 25px;
}

.py-1 {
  padding: 5px 0;
}

.py-2 {
  padding: 10px 0;
}

.py-3 {
  padding: 15px 0;
}

.py-4 {
  padding: 20px 0;
}

.py-5 {
  padding: 25px 0;
}

.mx-1 {
  margin: 0 5px;
}

.mx-2 {
  margin: 0 10px;
}

.mx-3 {
  margin: 0 15px;
}

.mx-4 {
  margin: 0 20px;
}

.mx-5 {
  margin: 0 25px;
}

.w-100 {
  width: 100% !important;
}

h1 {
  font-size: 24px;
  color: #4091cf;
}

.text-block {
  background-color: white;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 15px;
  width: 100%;
  color: #6f6f6f;
  font-size: 15px;
  font-weight: 100;
}
.text-block p {
  margin: 10px 0 0;
}
.text-block a {
  color: #4091cf;
  text-decoration: underline;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-success {
  color: #94D336;
}

.text-info {
  color: #92D3EE;
}

.text-disabled {
  color: #D7D8DA;
}

.control-point__requirements {
  cursor: default;
  width: 100%;
}

.about {
  cursor: default;
}

.notes {
  align-self: center;
  width: calc(75% + 35px);
  padding-top: 20px;
}
.notes span {
  font-size: 14px;
}
.notes p {
  font-size: 12px;
}

.notes-dialog textarea {
  border-radius: 0;
}
.notes-dialog .mt-3 {
  margin-top: 0;
}
.notes-dialog .inp-addon {
  margin-top: 20px;
}
.notes-dialog .MuiInput-underline:before {
  border-bottom: 2px solid #c9d8da;
}

.photo-uploader {
  border-bottom: 2px solid #c9d8da;
}
.photo-uploader input {
  user-select: none;
}
@media screen and (max-width: 400px) {
  .photo-uploader {
    width: 100%;
  }
}
.photo-uploader span {
  font-size: 14px;
}
.photo-uploader p {
  font-size: 12px;
}
.photo-uploader__control {
  padding: 0 25px;
  width: 100%;
}
.photo-uploader__control .form-error__text {
  margin-left: calc(5% + 35px);
}
@media screen and (max-width: 400px) {
  .photo-uploader__control .form-control {
    width: 100%;
    display: flex;
    margin-right: 0;
    margin-left: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .photo-uploader__control .MuiListItem-gutters {
    padding: 0;
  }
}
.photo-uploader .photo {
  max-height: 84px;
  min-height: 84px;
  max-width: 79px;
  min-width: 79px;
}
.photo-uploader .photo__list {
  display: flex;
  margin-bottom: 35px;
  width: 100%;
}
.photo-uploader .photo__wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 5px;
}
.photo-uploader .photo__delete-text {
  color: #e50a0a;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}
.photo-uploader .photo__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.photo-uploader .photo__add-button {
  margin-top: 15px;
  margin-left: auto;
  width: auto;
  padding: 0;
}

.form-error__text {
  font-size: 12px;
  color: #ff1d1d;
}

.form-control {
  width: 90%;
  display: flex;
  margin-right: 5%;
  margin-left: 5%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.form-control__icons {
  margin-right: 10px;
  margin-top: 10px;
  width: 25px;
  height: 25px;
}
.form-control__input {
  width: 100%;
}
.form-control__actions {
  min-width: 48px;
}

.notes-slide {
  width: 100%;
  min-height: 40px;
}
.notes-slide__button {
  width: 100%;
  margin-left: 5%;
  margin-right: 5%;
}
.notes-slide .btn-secondary {
  width: initial;
}

.accept-tos {
  background-color: #ffffff;
  padding: 15px;
  margin-top: 30px;
  border-radius: 15px;
}
.accept-tos__title {
  text-align: center;
  padding-top: 10px;
  margin-bottom: 30px;
  color: #4091cf;
}
.accept-tos__content {
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  margin-bottom: 30px;
  color: #6f6f6f;
}
.accept-tos__link {
  text-decoration: underline;
  color: #4091cf;
  padding-left: 5px;
}
.accept-tos__failed {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #fba92c;
}
.accept-tos__actions {
  display: flex;
}
.accept-tos__decline-button {
  margin-right: 5px;
  background-color: #ffffff;
  border: 1px solid #4091cf;
  color: #4091cf;
}
.accept-tos__accept-button {
  margin-left: 5px;
}

.control-instance-timer {
  cursor: default;
  color: #7dcfed;
  font-weight: 700;
  border: 2px solid #7dcfed;
  border-radius: 10px;
  display: flex;
  padding: 5px 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.control-instance-timer__hours {
  padding-right: 5px;
}

.offline .base-error {
  border-radius: 15px;
  margin-top: 30px;
  min-width: 300px;
  background-color: #ffffff;
  margin: 20px;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
  background: transparent;
  box-shadow: none;
}
.offline .base-error__error-title {
  padding-top: 10px;
  padding-bottom: 20px;
  color: #fba92c;
  color: #6f6f6f;
}
.offline .base-error__close-button.MuiIconButton-root {
  margin-top: -8px;
  margin-left: 10px;
}
.offline .base-error__error-info {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  margin-bottom: 30px;
  color: #6f6f6f;
  font-size: 14px;
}
.offline .base-error__button-refresh {
  text-align: center;
  background-color: 4091cf;
  color: #ffffff;
}

.snackbars {
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px;
}
.snackbars .snackbar {
  border: 1px solid #008000;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  margin-bottom: 20px;
  background-color: #f9f9f9;
}
.snackbars .snackbar--success {
  border-color: #008000;
}
.snackbars .snackbar--error {
  border-color: #ff0000;
}
.snackbars .snackbar__content {
  padding: 5px 0;
}
.snackbars .snackbar__title {
  color: #000000;
  opacity: 0.85;
}
.snackbars .snackbar__body {
  font-size: 12px;
  padding-top: 10px;
  color: #838383;
}
.snackbars .snackbar__actions {
  margin-left: 10px;
  align-self: center;
  justify-self: center;
}
.snackbars .snackbar__actions .MuiIconButton-root {
  padding: 5px;
}
.snackbars .snackbar__actions .MuiIconButton-root.success {
  color: #008000;
}
.snackbars .snackbar__actions .MuiIconButton-root.error {
  color: #ff0000;
}

.control-point-form .item-content {
  padding: 0;
}
.control-point-form .buttons-block {
  margin-top: 15px;
}
.control-point-form__content {
  padding: 10px 15px;
  padding-top: 20px;
}
.control-point-form__button {
  padding-bottom: 20px;
}

