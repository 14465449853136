* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  outline: 0;
}
p {
  color: #6f6f6f;
  padding: 3px 0;
}
body,
html {
  height: 100%;
  background-color: #e4eff1;
  position: relative;
  z-index: 0;
}
input {
  outline: 0;
}
.page-wrapper {
  position: relative;
  min-height: calc(100vh - 35px);
  padding-bottom: 85px;
  max-width: 800px;
  margin: 0 auto;
}
.content {
  border-top: 2px solid #cad8da;
  width: 100%;
  height: 100%;
  padding: 0 10px;
}

a {
  text-decoration: none;
  color: inherit;
}

section {
  margin: 0 15px;
}

textarea {
  width: 100%;
  height: 100px;
  border: 2px solid #cad8da;
  padding: 10px;
  border-radius: 15px;
  max-width: 100%;
}

select {
  height: 30px;
  color: #6f6f6f;
  border: 0;
  border-bottom: 2px solid #ecf0f0;
  width: 100%;

  &:disabled {
    color: lightgray;
    cursor: not-allowed;
  }
}
