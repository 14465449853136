.control-point-form {
  .item-content {
    padding: 0;
  }
  .buttons-block {
    margin-top: 15px;
  }
  &__content {
    padding: 10px 15px;
    padding-top: 20px;
  }
  &__button {
    padding-bottom: 20px;
  }
}